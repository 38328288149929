import { createElement } from "react";
import cn from "classnames";
import css from "./Typography.module.css";

function Typography({
  element = "span",
  className,
  styleIndex = 0,
  hoverStyleIndex = null,
  stroke = false,
  children,
  ...props
}) {
  return createElement(
    element,
    {
      ...props,
      className: cn(
        className,
        css.baseStyles,
        css[`style${styleIndex}`],
        css[`hoverStyle${hoverStyleIndex}`],
        {
          [css[`stroke${styleIndex}`]]: stroke,
        }
      ),
    },
    children
  );
}

export default Typography;
