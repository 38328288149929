import React from "react";
import cn from "classnames";
import Image from "../image";
import Typography from "../Typography";
import youtube from "../../images/youtube.svg";

import css from "./ImageLink.module.css";

function ImageLink(props) {
  return (
    <a className={css.container} {...props}>
      <Image src="last-min.png" className={cn(css.image)} />
      <Image src="monkey-dust.png" className={cn(css.image, css.clocks)} />
      <Image
        src="eyes.png"
        className={css.eyes}
        style={{ position: "absolute" }}
      />
      <Typography styleIndex="2" stroke className={css.text}>
        WATCH
      </Typography>
      <img src={youtube} alt="youtube logo" className={css.youtube}></img>
    </a>
  );
}

export default ImageLink;
