import React from "react";

import Image from "../image";
import SidewaysScroller from "../SidewaysScroller";
import Typography from "../Typography";
import DorchaLink from "../DorchaLink";
import AnimatingImage from "../AnimatingImage";

import css from "./HeadingImage.module.css";

const quotes = [
  {
    quote:
      "Whether you’re always in the kitchen or crying on the stairs, Honey Badger is the crucial party sound of 2020.",
    author: "God Is In The TV Zine",
  },
  {
    quote: "Angelic to freakjazz in the blink of an eye",
    author: "Weirdo Shrine",
  },
  {
    quote:
      "...Pieces that never stand still, that change shape, skin and content, a bit like Alice in Wonderland, but perhaps look more like the Mad Hatter…",
    author: "Infinite Jest",
  },
  { quote: "An enigmatic and beguiling listen", author: "Echos and Dust" },
  {
    quote:
      "I would give anything to be able to see Dorcha live, I can’t imagine quite how life affirming and strange a night with these people in front of me would be. But for now, I’m not leaving the house, it’s too scary out there. So I’ll put the record on again. And again.",
    author: "Fighting Boredom",
  },
  {
    quote:
      "Through a deft balance of skronky garage rock with baroque art pop, the band deliver a mind-boggling debut full of left turns and startling instrumental choices.",
    author: "Patrick Lyons, Bandcamp",
  },
  { quote: "A fervent, intoxicating melting pot", author: "NARC" },
  {
    quote:
      "…Dorcha take the listener by the hand and lead them along with a series of delirious earworms.",
    author: "Counteract",
  },
  { quote: "A fearless sentiment", author: "Loud & Quiet" },
  {
    quote:
      "Honey Badger is a fun-loaded psychedelic pick’n’ mix of a record which surprises at every turn.⁣..",
    author: "Norman Records 9/10",
  },
];

function HeadingImage({ heading, imgSrc }) {
  return (
    <div className={css.container}>
      <Image src={imgSrc} className={css.image} />
      <div className={css.overlayContainer}>
        <SidewaysScroller
          duration={quotes.length * 25}
          className={css.sidewaysScroller}
        >
          {quotes.map(({ quote, author }) => (
            <React.Fragment key={quote}>
              <Typography
                styleIndex="3"
                stroke
                style={{ fontSize: "2vw", display: "inline" }}
              >
                "{quote}"{" "}
              </Typography>
              <Typography
                styleIndex="2"
                stroke
                style={{ fontSize: "2vw", display: "inline" }}
              >
                {author}.{" "}
              </Typography>
            </React.Fragment>
          ))}
        </SidewaysScroller>
        <AnimatingImage
          imgSrc="Wagginghand.png"
          animationName="flash"
          duration="1300"
          className={css.wagHand}
        />
        <DorchaLink
          className={css.titleLink}
          text={
            <Typography
              element="span"
              stroke
              hoverStyleIndex="1"
              className={css.titleSpan}
            >
              {heading}
            </Typography>
          }
          isExternal
          href="https://boxrecordsshop.bandcamp.com/album/honey-badger"
        ></DorchaLink>
      </div>
    </div>
  );
}

export default HeadingImage;
