import React, { useState } from "react";
import { Link } from "gatsby";
import cn from "classnames";

import GlitchText from "../GlitchText";
import { isTouchDevice } from "../../libs";
import css from "./DorchaLink.module.css";

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
function DorchaLink({ isDisabled, isSpecialHover, ...props }) {
  if (isDisabled || isSpecialHover) {
    return (
      <SpecialLink
        {...props}
        isDisabled={isDisabled}
        isSpecialHover={isSpecialHover}
        fallback={<NormalLink {...props} />}
      />
    );
  } else return <NormalLink {...props} />;
}

function NormalLink({
  text,
  className,
  isGlitching,
  isExternal,
  hoverText,
  ...props
}) {
  if (isExternal) {
    return (
      <a name={text} className={cn(css.link, className)} {...props}>
        <span aria-hidden="true" className={css.hackSpan}>
          |
        </span>
        <GlitchText text={text} isGlitching={isGlitching} />
      </a>
    );
  }
  return (
    <Link name={text} className={cn(css.link, className)} {...props}>
      <span aria-hidden="true" className={css.hackSpan}>
        |
      </span>
      <GlitchText text={text} isGlitching={isGlitching} />
    </Link>
  );
}

function SpecialLink({
  text,
  isDisabled,
  isSpecialHover,
  className,
  isGlitching,
  hoverText,
  isExternal,
  ...props
}) {
  const [isHovered, setIsHovered] = useState(false);
  const isTouchScreen = isTouchDevice();

  function handleMobileClick(e) {
    setIsHovered(!isHovered);
  }
  if (isDisabled) {
    if (!isTouchScreen) {
      return (
        <>
          <span aria-hidden="true" className={css.hackSpan}>
            {" "}
          </span>
          <span
            className={cn(
              css.link,
              css.disabledLink,
              {
                [css.disabledLinkHover]: isHovered,
              },
              { [css.specialLinkHover]: isHovered }
            )}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <GlitchText
              text={isHovered ? hoverText || "coming soon" : text}
              isGlitching={isGlitching}
            />
          </span>
        </>
      );
    }
    return (
      <>
        <span aria-hidden="true" className={css.hackSpan}>
          {" "}
        </span>

        <span
          className={cn(css.link, css.disabledLinkMob, {
            [css.disabledLinkActiveMob]: isHovered,
            [css.specialLinkHover]: isHovered,
          })}
          onClick={handleMobileClick}
        >
          <GlitchText
            text={isHovered ? "coming soon" : text}
            isGlitching={isGlitching}
          />
        </span>
      </>
    );
  }
  if (isSpecialHover) {
    return (
      <Link name={text} className={cn(css.link, className)} {...props}>
        <span aria-hidden="true" className={css.hackSpan}>
          {" "}
        </span>
        <span
          className={cn(css.link, css.disabledLink, {
            [css.specialLinkHover]: isHovered,
          })}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <GlitchText
            text={isHovered ? hoverText || "coming soon" : text}
            isGlitching={isGlitching}
          />
        </span>
      </Link>
    );
  }
}

export default DorchaLink;
