import React from "react";
import cn from "classnames";

import css from "./SidewaysScroller.module.css";

function SidewaysScroller({ children, className, duration }) {
  return (
    <p
      className={cn(css.container, className)}
      style={duration ? { "--duration": `${duration}s` } : {}}
    >
      <span>{children}</span>
    </p>
  );
}

export default SidewaysScroller;
