import React from "react";
import cn from "classnames";
import Image from "../image";

import css from "./AnimatingImage.module.css";

function AnimatingImage({ imgSrc, className, animationName, duration = 1000 }) {
  return (
    <Image
      src={imgSrc}
      className={cn(css.baseStyles, css[animationName], className)}
      style={{ "--duration": `${duration}ms` }}
    ></Image>
  );
}

export default AnimatingImage;
