import React from "react"
import cn from "classnames"

import css from "./GlitchText.module.css"

function GlitchText({ text, isGlitching }) {
  return (
    <span data-text={text} className={cn({ [css.glitch]: isGlitching })}>
      {text}
    </span>
  )
}

export default GlitchText
