import React from "react";

import Layout from "../components/layout";
import AlbumPage from "../components/AlbumPage";

const Album = ({ location }) => {
  return (
    <Layout image="album-background.png" location={location}>
      <AlbumPage />
    </Layout>
  );
};

export default Album;
