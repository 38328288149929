import React from "react";
import cn from "classnames";

import HeadingImage from "../HeadingImage";
import DorchaLink from "../DorchaLink";
import Typography from "../Typography";
import ImageLink from "../ImageLink";

import useWindowSize from "../../hooks/useWindowSize";

import css from "./AlbumPage.module.css";

import artsCouncilLogo from "../../images/arts-council.svg";
// import honeyBadgerGif from "../../images/running-badger.gif";
import honeyBadgerGif from "../../images/dancing-badger.gif";
// import { ALBUM_VIDEO_URL, isVideoReady } from "../../constants";

const albumStockists = [
  {
    name: "Box Records",
    link: "https://boxrecordsshop.bandcamp.com/album/honey-badger",
  },
  {
    name: "Norman Records",
    link: "https://www.normanrecords.com/records/183367-dorcha-honey-badger",
  },
  {
    name: "Piccadilly Records",
    link: "https://www.piccadillyrecords.com/counter/product.php?pid=137164",
  },
  {
    name: "Rough Trade",
    link: "https://www.roughtrade.com/gb/dorcha/honey-badger",
  },
  {
    name: "Juno",
    link: "https://www.juno.co.uk/products/dorcha-honey-badger/790339-01/",
  },
  {
    name: "Sister Ray",
    link: "https://sisterray.co.uk/products/honey-badger",
  },
  {
    name: "W.H.Smiths",
    link:
      "https://www.whsmith.co.uk/products/honey-badger/0744109282294.html?gclid=Cj0KCQjwyZmEBhCpARIsALIzmnKZ63PrbJ1dMAmHyEeMuc-Qpm1ZDFZ8sCBxQMJyYIs2Yat-c1d2Q-4aAivPEALw_wcB&gclsrc=aw.ds",
  },
];

const highlights = [
  "BANDCAMP ALBUM OF THE DAY 16/11/20",
  "9/10 from Norman Records",
];
function AlbumPage() {
  const { width } = useWindowSize();
  return (
    <div className={cn(css.container)}>
      <p>
        <DorchaLink
          text={
            <Typography
              element="span"
              styleIndex="1"
              hoverStyleIndex="0"
              stroke
              style={{ fontSize: "min(5vw, 1em)" }}
            >
              {"<-"} Home
            </Typography>
          }
          to="/"
          className={css.homeLink}
          state={{
            isBackFromBlob: true,
          }}
        />
      </p>

      <section className={css.infoSection}>
        <DorchaLink
          className={css.titleLink}
          text={
            <Typography element="span" stroke>
              HONEY BADGER
            </Typography>
          }
          isExternal
          href="https://boxrecordsshop.bandcamp.com/album/honey-badger"
        ></DorchaLink>
        <HeadingImage imgSrc="dorcha-eyes.jpg" heading="HONEY BADGER OUT NOW" />
        <Typography
          element="p"
          styleIndex="0"
          style={{
            fontSize: "min(5vw, 1.5em)",
          }}
        >
          <Typography element="span" styleIndex="4">
            Released November 6, 2020 on Box Records{" "}
          </Typography>
          <span role="img" aria-label="heart">
            ❤️
          </span>
        </Typography>
        {highlights.map(text => (
          <p
            style={{ fontSize: "min(5vw, 2em)", textAlign: "center" }}
            key={text}
          >
            <Typography styleIndex="0" stroke>
              *{" "}
            </Typography>
            <Typography element="span" styleIndex="4" stroke>
              {text}
            </Typography>
            <Typography styleIndex="0" stroke>
              {" "}
              *
            </Typography>
          </p>
        ))}
        <img
          src={honeyBadgerGif}
          alt="dancing honey badger"
          className={css.badgerGif}
        ></img>
        <DorchaLink
          className={css.buyOnline}
          target="_blank"
          rel="noopener norefferer"
          style={{ display: "block" }}
          text={
            <Typography
              element="span"
              styleIndex="0"
              hoverStyleIndex="1"
              stroke
            >
              <span role="img" aria-label="star" className={css.star}>
                ⭐️
              </span>{" "}
              BUY HERE{" "}
              <span role="img" aria-label="star" className={css.star}>
                ⭐️
              </span>
            </Typography>
          }
          isExternal
          href="https://boxrecordsshop.bandcamp.com/album/honey-badger"
        ></DorchaLink>
        <ul style={{ marginTop: 0, textAlign: "center", padding: 0 }}>
          {albumStockists.map(({ name, link }, i) => (
            <li key={link}>
              <DorchaLink
                target="_blank"
                rel="noopener norefferer"
                text={
                  <Typography
                    key={i}
                    element="span"
                    styleIndex="4"
                    hoverStyleIndex="0"
                    stroke
                    style={{ fontSize: "min(5vw, 2em)" }}
                  >
                    {name}{" "}
                  </Typography>
                }
                isExternal
                href={link}
              ></DorchaLink>
            </li>
          ))}
        </ul>
        <ImageLink href="https://www.youtube.com/playlist?list=PLF47ZKIRyWy3aEhDFLWwQxKq3SO1BAWxl" />
        <Typography
          element="p"
          styleIndex={width > 500 ? "0" : "4"}
          stroke
          style={{
            fontSize: width > 500 ? "min(10vw, 4em)" : "min(5vw, 2em)",
            textAlign: "center",
          }}
        >
          FULL ALBUM VISUAL
          <br />
          <span role="img" aria-label="star" className={css.star}>
            ⭐️
          </span>{" "}
          COMING SOON{" "}
          <span role="img" aria-label="star" className={css.star}>
            ⭐️
          </span>
        </Typography>
      </section>
      <img
        className={css.artsCouncil}
        src={artsCouncilLogo}
        alt="arts council logo"
      />
    </div>
  );
}

export default AlbumPage;
